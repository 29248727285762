import React, { useState, useEffect } from "react";
import { Redirect, Switch, Route, Link } from "react-router-dom";
import Content from "./components/Content";
import Links from "./components/Links";
import Spiders from "./components/Spiders";
import Monitor from "./components/Monitor";
import Stats from "./components/Stats";
import Spider from "./components/Spider";
import CardsView from "./components/CardsView";
import query_db from "./components/GetSpiders";
import "bootstrap/dist/css/bootstrap.min.css";
import AuthService from "./services/auth.service";
import Login from "./components/Login";
import { MdExitToApp } from "react-icons/md";

import "./App.css";

const App = () => {
  const [currentUser, setCurrentUser] = useState(undefined);
  
  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) setCurrentUser(user);
  }, []);

  const logOut = () => {
    AuthService.logout();
  };

  const logoutComponent = () => {
    AuthService.logout();
    return <Login />;
  };
  const fetched_data = query_db();
  return (
    <div>
      <nav
        style={{ borderRadius: 0 }}
        className="navbar navbar-expand-xl navbar-dark bg-dark"
      >
        <Link to={"/check"} className="navbar-brand">
          <img
            className="logo"
            src="logo.png"
            alt=""
          />
        </Link>
        <div className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link to={"/check"} className="nav-link">
              <h5>Check</h5>
            </Link>
          </li>
        </div>
        <div className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link to={"/links"} className="nav-link">
              <h5>Links</h5>
            </Link>
          </li>
        </div>
        <div className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link to={"/spiders"} className="nav-link">
              <h5>Spiders</h5>
            </Link>
          </li>
        </div>
        <div className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link to={"/articles"} className="nav-link">
              <h5>Articles</h5>
            </Link>
          </li>
        </div>
        <div className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link to={"/monitor"} className="nav-link">
              <h5>Monitor</h5>
            </Link>
          </li>
        </div>
        <div className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link to={"/stats"} className="nav-link">
              <h5>Stats</h5>
            </Link>
          </li>
        </div>
        {currentUser ? (
              <Link className="exit-button" to={"/"}>
                <h3><MdExitToApp onClick={() => logOut()} /></h3>
              </Link> )
           : (
            <div></div>
          )}
      </nav>

      <div className="App-header">
        <Switch>
          <Route exact path={["/", "/check"]} component={Content} />
          <Route exact path={["/links", "/links"]} component={Links} />
          <Route exact path={"/articles"} component={CardsView} />
          <Route exact path={"/monitor"} component={Monitor} />
          <Route exact path={"/stats"} component={Stats} />
          <Route
            exact
            path={"/spiders"}
            render={(props) => (
              <Spiders {...props} fetched_data={fetched_data} />
            )}
          />
          <Route
            path={"/spider/:id"}
            children={<Spider />}
          />
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" render={logoutComponent} />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default App;
