import axios from "axios";

const API_URL = "https://admin-api.infobring.io/";


const config = {
  headers: {
    "accept": "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
  }
};

const register = (fullname, email, password) => {
  const data = {
    fullname: fullname,
    email: email,
    password: password,
    is_active: false,
    is_superuser: false,
    is_verified: false,
  };

  return axios.post(API_URL + "auth/token", data, config);
};

const login = async (username, password) => {
  const params = new URLSearchParams();
  params.append("username", username);
  params.append("password", password);

  var jwt = "";
  return await axios
    .post(API_URL + "auth/token", params, {
      headers: {
        "accept": "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then((response) => {
      if (response.data.access_token) {
        jwt = response.data.access_token;
        console.log(jwt);
        localStorage.setItem(
          "user",
          JSON.stringify({ username: username, token: jwt })
        );
        return jwt;
      }
    });

};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export default {
  register,
  login,
  logout,
  getCurrentUser,
};
