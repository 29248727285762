import React from "react";
import { Card, Icon, Image } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import Highlight from "react-highlighter";
import "../App.css";

const ACard = (props) => {

  function createMarkup(text) {
    return {__html: text};
  }
  

  let highlited_words = props.keywords
  if (highlited_words.includes('"'))
    highlited_words = highlited_words.replaceAll('"', '');
  else if (highlited_words.includes(' '))
    highlited_words = new RegExp('(' +  highlited_words.split(" ").join('|') + ')')

  let mod_text = props.card_data.text
  
  
  return (<Card centered={true}>
    <Card.Content className="cards-row" centered={false}>
      <Image
        className="card-image"
        floated="left"
        inline={true}
        size="medium"
        src={props.card_data.image}
      />
      <div className="card-meta">
      <Card.Meta>
          <span>
            <img alt="https://www.iran16.com/wp-content/uploads/2016/05/404.png" src={props.card_data.website_icon}/>&nbsp;{props.card_data.website_name}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a target="_blank" rel="noreferrer" href={"https://" + props.card_data.base_url}>
            <Icon name="world" />
            &nbsp;{props.card_data.base_url}
          </a>
          </span>
        </Card.Meta>
        <Card.Meta>
          <span>
            <Icon name="clock" />&nbsp;{props.card_data.published}
          </span>
        </Card.Meta>
        
      </div>
    </Card.Content>

    <Card.Content extra centered={false}>
      <Card.Header dir={(props.card_data.language === "ar") ? "rtl" : "ltr"}>
        <a target="_blank" rel="noreferrer" id="header" href={props.card_data.url}>
         <Highlight search={highlited_words}>{props.card_data.title}</Highlight>
        </a>
      </Card.Header>
      <Card.Description className="card-description" dir={(props.card_data.language === "ar") ? "rtl" : "ltr"}>
        <Highlight search={highlited_words}>{mod_text}</Highlight>
      </Card.Description>
    </Card.Content>
  </Card>
  )};


export default ACard;
