import React, { useState, useEffect, useCallback } from "react";
import { Container, Loader, Statistic } from "semantic-ui-react";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { BsCalendar } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import authHeader from "../services/auth-header";
import AuthService from "../services/auth.service";

import "@inovua/reactdatagrid-community/index.css";

const handleStartDate = (value) => {
  // Ensure value is a valid ISO 8601 date string
  const date = new Date(value);

  // Check if date is valid
  if (isNaN(date.getTime())) return "";

  // Return a formatted string (e.g., "Dec 17, 2023 02:58")
  return date.toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

const gridStyle = { minHeight: 400, width: "160vh" };

const defaultFilterValue = [
  { name: "base_url", operator: "startsWith", type: "string", value: "" },
  { name: "start", operator: "startsWith", type: "date", value: "" },
  { name: "links", operator: "startsWith", type: "number", value: "" },
  { name: "articles", operator: "startsWith", type: "number", value: "" },
  { name: "avg_time", operator: "gte", type: "number", value: 0 },
  { name: "downloaded", operator: "gte", type: "number", value: 0 },
  { name: "errors", operator: "gte", type: "number", value: 0 },
];

const columns = [
  { name: "id", header: "Id", defaultVisible: false },
  {
    name: "base_url",
    header: "Base URL",
    sortable: true,
    defaultFlex: 2,
    sort: () => {},
  },
  {
    name: "start",
    header: "Last Run Date",
    sortable: true,
    defaultFlex: 1.5,
    sort: () => {},
  },
  {
    name: "links",
    header: "Links",
    sortable: true,
    defaultFlex: 1,
    sort: () => {},
  },
  {
    name: "articles",
    header: "Articles",
    sortable: true,
    defaultFlex: 1,
    sort: () => {},
  },
  {
    name: "avg_time",
    header: "Avg. Time / Article",
    defaultFlex: 1.4,
    sort: () => {},
  },
  { name: "downloaded", header: "Downloaded", defaultFlex: 1, sort: () => {} },
  { name: "errors", header: "Total Errors", defaultFlex: 1, sort: () => {} },
];

const query_db = ({ skip, limit, sortInfo }, filterValue) => {
  //   alert(JSON.stringify(limit))

  const base_url = "https://admin-api.infobring.io/daily_stats";

  let url =
    base_url +
    "/skip/" +
    skip +
    "/limit/" +
    limit +
    "/filter/" +
    JSON.stringify(filterValue);
  // "?min_date=" +
  // handleDate(start) +
  // "&max_date=" +
  // handleDate(end);

  if (sortInfo !== null && sortInfo !== undefined)
    url += "?sort_field=" + sortInfo.name + "&sort_dir=" + sortInfo.dir;

  return fetch(url, {
    // mode: 'cors',
    // credentials: 'include',
    method: "GET",
    headers: authHeader(),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(`Received data:`, data);

      if (data.error !== undefined) {
        data = [];
        let totalCount = 0;
        return Promise.resolve({ data, count: parseInt(totalCount) });
      }
      if (
        data.results === undefined ||
        data.results[0].metadata === undefined ||
        data.results[0].metadata.length === 0
      ) {
        let data = [];
        let totalCount = 0;
        return Promise.resolve({ data, count: parseInt(totalCount) });
      }
      // alert(JSON.stringify(data));
      let totalCount = data.results[0].metadata[0].total;
      data = data.results[0].data.map((result) => ({
        id: result._id.$oid,
        base_url: result.settings.base_url,
        start: handleStartDate(result.stats.start.$date),
        links: parseInt(result?.daily_stats?.links_count, 10),
        articles: parseInt(result?.daily_stats?.articles_count, 10),
        avg_time: result?.daily_stats?.avg_elapsed_per_article,
        downloaded: result?.daily_stats?.total_download,
        errors: result?.daily_stats?.total_errors,
      }));
      console.log(`Processed data for grid:`, data);

      return Promise.resolve({ data, count: parseInt(totalCount) });
    })
    .catch((err) => {
      console.log(err);
    });
};

const fetch_stats = async (setStats, setStatsLoading, setError) => {
  //   alert(JSON.stringify(limit))

  const base_url = "https://admin-api.infobring.io/stats";

  let url = base_url;

  setStatsLoading(true);
  try {
    try {
      const response = await fetch(url, {
        // mode: 'cors',
        // credentials: 'include',
        method: "GET",
        headers: authHeader(),
      });
      const data = await response.json();
      // alert(JSON.stringify(data));
      setStats(data);
    } catch (err) {
      setError(err);
    }
  } finally {
    setStatsLoading(false);
  }
};

const handleDate = (value) => {
  var d = new Date();
  d.setTime(Number(value));
  return d.toString().slice(3, 15).trim();
};

const Stats = () => {
  let history = useHistory();
  const [sortInfo, setSortInfo] = useState(null);
  const [stats, setStats] = useState({
    articles: [{ total: 0 }],
    spiders: [{ total: 0 }],
    dynamic_data: [{ total_time: 0, total_download: 0, total_items: 1 }],
  });
  const [stats_loading, setStatsLoading] = useState(false);
  const [stats_error, setError] = useState({});
  const [articles, setArticles] = useState([]);
  const [missing, setMissing] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState(defaultFilterValue);
  // const [state, setDate] = useState({
  //   start: moment().subtract(60, "days"),
  //   end: moment().add(1, "days"),
  // });
  // const { start, end } = state;
  // const handleCallback = (start, end) => {
  //   setDate({ start, end });
  // };
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const dataSource = useCallback(query_db, [filterValue]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const onCellClick = useCallback((event, cellProps) => {
    const { columnIndex, rowIndex, value } = cellProps;

    const redirectLink = (c, r) => {
      if (c === 1) {
        window.open(value, "_blank", "toolbar=0,location=0,menubar=0");
      }
    };
    redirectLink(columnIndex, rowIndex);
  }, []);

  useEffect(() => {
    fetch_stats(setStats, setStatsLoading, setError);
    return () => {
      return [];
    };
  }, []);

  let isMobile = width <= 768;

  let label = "";

  // if (isMobile === false)
  //   label = start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY");
  // else label = "";

  if (!AuthService.getCurrentUser()) {
    history.push("/login");
    window.location.reload();
  }
  return (
    <>
      <div
        className="report-header"
        style={{
          display: loading ? "none" : "block",
          marginTop: "50px",
        }}
      >
        <h2>Stats Charts</h2>
        <br />
        <Container>
        <Statistic.Group className="animate" style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'center' }}>
            <Statistic color="green">
              <Statistic.Value>
                {stats_loading ? (
                  <Loader active inline />
                ) : (
                  stats?.articles[0]?.total.toLocaleString("en-US")
                )}
              </Statistic.Value>
              <Statistic.Label style={{ color: "white" }}>
                Total Articles
              </Statistic.Label>
            </Statistic>
            <Statistic color="teal">
              <Statistic.Value>
                {stats_loading ? (
                  <Loader active inline />
                ) : (
                  stats?.spiders[0]?.total
                )}
              </Statistic.Value>
              <Statistic.Label style={{ color: "white" }}>
                Active Spiders
              </Statistic.Label>
            </Statistic>
            <Statistic color="orange">
              <Statistic.Value>
                {stats_loading ? (
                  <Loader active inline />
                ) : (
                  Math.round(stats?.dynamic_data[0]?.total_download / 1000000) +
                  "MB"
                )}
              </Statistic.Value>
              <Statistic.Label style={{ color: "white" }}>
                Download (Per Day)
              </Statistic.Label>
            </Statistic>
            <Statistic color="yellow">
              <Statistic.Value>
                {stats_loading ? (
                  <Loader active inline />
                ) : (
                  Math.round(
                    stats?.dynamic_data[0]?.total_time /
                      stats?.dynamic_data[0]?.total_items,
                    2
                  ) + "s"
                )}
              </Statistic.Value>
              <Statistic.Label style={{ color: "white" }}>
                AVG. Time / Article
              </Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Container>

        <br />
        <br />
        {/* <Container style={{ display: "flex", justifyContent: "center" }}>
          <DateRangePicker
            initialSettings={{
              startDate: start.toDate(),
              endDate: end.toDate(),
              ranges: {
                Today: [moment().toDate(), moment().toDate()],
                Yesterday: [
                  moment().subtract(1, "days").toDate(),
                  moment().subtract(1, "days").toDate(),
                ],
                "Last 7 Days": [
                  moment().subtract(6, "days").toDate(),
                  moment().toDate(),
                ],
                "Last 30 Days": [
                  moment().subtract(29, "days").toDate(),
                  moment().toDate(),
                ],
                "This Month": [
                  moment().startOf("month").toDate(),
                  moment().endOf("month").toDate(),
                ],
                "Last Month": [
                  moment().subtract(1, "month").startOf("month").toDate(),
                  moment().subtract(1, "month").endOf("month").toDate(),
                ],
                "Last 3 Month": [
                  moment().subtract(3, "month").startOf("month").toDate(),
                  moment().subtract(1, "month").endOf("month").toDate(),
                ],
              },
            }}
            onCallback={handleCallback}
          >
            <div
              id="reportrange"
              className="form-control cal-input"
              aria-label="Small"
            >
              <BsCalendar />
              &nbsp;&nbsp;<span>{label}</span>
            </div>
          </DateRangePicker>
        </Container> */}
      </div>
      <div
        class="text-center"
        style={{
          color: "rgba(255, 146, 255, 1)",
          paddingTop: "10px",
          display: loading ? "block" : "none",
        }}
      >
        <div class="spinner-border" role="status"></div>
      </div>

      <div
        style={{
          flex: "None",
          display: loading ? "none" : "block",
          marginTop: "-50px",
        }}
      >
        <h2>Stats by Spiders (for past 24 hours)</h2>
        <ReactDataGrid
          idProperty="id"
          style={gridStyle}
          onSortInfoChange={setSortInfo} // Add this prop to update sortInfo state
          checkboxOnlyRowSelect={true}
          columns={columns}
          pagination={"remote"}
          remoteSort={true}
          dataSource={(d) => dataSource(d, filterValue, sortInfo)}
          defaultFilterValue={filterValue}
          onFilterValueChange={setFilterValue}
          defaultLimit={10}
          sortable={true}
          editable={false}
          onCellClick={onCellClick}
          // rtl={true}
        />
        <br />
        <br />
      </div>
    </>
  );
};

export default Stats;
