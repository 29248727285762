import React, { useState, useEffect } from "react";
import { Pagination, Container, Header, Segment } from "semantic-ui-react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import moment from "moment";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { BsCalendar, BsFilter } from "react-icons/bs";
import ACard from "./ACard";
import { useHistory } from "react-router-dom";
import authHeader from "../services/auth-header";
import AuthService from "../services/auth.service";

import "../App.css";

const handleDate = (value) => {
  var d = new Date();
  d.setTime(Number(value));
  return d.toString().slice(3, 15).trim();
};

const clean_text = (mod_text) => {
  mod_text = mod_text.replaceAll("&quot;", '"');
  mod_text = mod_text.replaceAll("&nbsp;", " ");
  mod_text = mod_text.replaceAll("&zwnj;", " ");
  mod_text = mod_text.replaceAll("&lrm;", " ");
  mod_text = mod_text.replaceAll("&ndash;", "-");

  return mod_text;
}

const get_websites = async () => {
  const base_url = "https://admin-api.infobring.io";
  const url = base_url + "/websites";
  return fetch(url, { 
    // mode: 'cors',
    // credentials: 'include',
    method: "GET", 
    headers: authHeader() 
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error === undefined) {
        data = data.websites.map((result) => ({
          label: result.name, 
          value: result.name 
        }))
        return data;
      }

    })   
}


const extract_text = (result, path) => {
  let text = "";

  text = result.highlights.map((highlight) => {
    // alert(JSON.stringify(highlight))
    return highlight.texts.map((part) => {
        if (part.type === "hit" && highlight.path === path) {
          return "<mark>" + clean_text(part.value) + "</mark>";
        } else if (highlight.path === path) {
          return clean_text(part.value);
        }
      })
      .join("");
  }).join("");

  if (text === "" || text === "``")
    if (path === "text") text = result.text;
    else if (path === "title") text = result.title;

  return text;

};

const get_countries = async () => {
  const base_url = "https://admin-api.infobring.io";
  const url = base_url + "/countries";
  return fetch(url, { 
    // mode: 'cors',
    // credentials: 'include',
    method: "GET", 
    headers: authHeader()
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error === undefined) {
        data = data.countries.map((result) => ({
          label: result, 
          value: result
        }))
        return data;
      }
    })   
}

const query_db = (
  { skip, limit, sortInfo },
  keywords,
  start,
  end,
  selectedCountries,
  selectedWebsites,
  setError
) => {

  let countries = ""
  selectedCountries.map((country) => {
    countries +=  country.value + ",";
  })
  if (countries[countries.length - 1] === ',')
    countries = countries.slice(0, countries.length - 1)
  
    let websites = "";
  selectedWebsites.map((website) => {
    websites += website.value + ",";
  });
  if (websites[websites.length - 1] === ",")
  websites = websites.slice(0, websites.length - 1);

  const base_url = "https://admin-api.infobring.io/";
  const search_str = (keywords === "") ? "[all_results]" : keywords;
  
  let url =
    base_url +
    "search/" +
    search_str +
    "/skip/" +
    skip +
    "/limit/" +
    limit +
    "?min_date=" +
    handleDate(start) +
    "&max_date=" +
    handleDate(end) +
    "&countries=" + countries +
    "&website_name=" + websites;

  if (sortInfo !== null && sortInfo !== undefined)
    url += "?sort_field=" + sortInfo.name + "?sort_dir=" + sortInfo.dir;

  // alert(url)
  return fetch(url, { 
    // mode: 'cors',
    // credentials: 'include',
    method: "GET", 
    headers: authHeader() 
  })
    .then((response) => response.json())
    .then((data) => {
      // alert(JSON.stringify(data));
      if (data.error !== undefined) {
        setError(data.error);
        data = [];
        let totalCount = 0;
        return { data, count: parseInt(totalCount) };
      }
      if (
        data.results === undefined ||
        data.results.data === undefined ||
        data.results.total_count === 0
      ) {
        let data = [];
        let totalCount = 0;
        return { data, count: parseInt(totalCount) };
      }

      let totalCount = data.results.total_count;
      // alert(JSON.stringify(data.results[0].data[0].highlights))
      var pattern = /^https?:\/\/[^/]+/i;
      data = data.results.data.map((result) => ({
        title: result.title, //extract_text(result, "title"),
        published: result.published.slice(0, 10).trim(),
        url: result.url,
        text:  result.text, //extract_text(result, "text"),
        website_name: result.website_name,
        image: result.image_url.toString().split(",")[0],
        base_url: result.homepage,
          // .match(pattern)
          // .pop()
          // .replace("https://", "")
          // .replace("www.", ""),
        website_icon:
          "https://www.google.com/s2/favicons?domain=" +
          result.url.match(pattern),
        language: result.language
      }));
      return { data, count: parseInt(totalCount) };
    });
};

const CardsView = () => {
  let history = useHistory();
  const [keywords, setKeywords] = useState("");
  const [cards_data, setCards_data] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [total_pages, setTotal_pages] = useState(0);
  const [total_articles, setTotal_articles] = useState(0);
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const [website_list, setWebsite_list] = useState([]);
  const [countries_list, setCountries_list] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([{'value': 'ae'}]);
  const [selectedWebsites, setSelectedWebsites] = useState([]);

  const handlePaginationChange = async (e, { activePage }) => {
    e.preventDefault();

    setActivePage(activePage);
    await update_data(activePage);
  };
  const paginator = (activePage, total, total_articles) => {
    return (
      <span
        style={{
          fontSize: "0.6em",
          display: total_articles === 0 ? "none" : "inline",
        }}
      >
        Page: {activePage} of {total}
        <Segment
          floated="right"
          size="mini"
          secondary
          className="pagination_bar"
        >
          <Pagination
            activePage={activePage}
            onPageChange={handlePaginationChange}
            totalPages={total}
          />
        </Segment>
      </span>
    );
  };

  const [state, setDate] = useState({
    start: moment().subtract(30, "days"),
    end: moment(),
  });
  const { start, end } = state;
  const handleCallback = (start, end) => {
    setDate({ start, end });
  };

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    const fetch_websites = async () => setWebsite_list(await get_websites());
    const fetch_countries = async () => setCountries_list(await get_countries());
    fetch_websites();
    fetch_countries();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;

  let label = "";

  if (isMobile === false)
    label = start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY");
  else label = "";

  const onFormSubmit = async (event) => {
    event.preventDefault();
    // if (keywords === "") {
    //   alert("Please enter some text.");
    //   return;
    // }
    setActivePage(1);
    await update_data(1);
  };

  const onFilter = async () => {
    setOpen(false);
    setActivePage(1);
    await update_data(1);
  };

  const update_data = async (value) => {
    const limit = 10;
    const skip = (value - 1) * limit;
    const sortInfo = null;
    const setError = () => {};
    const results = await query_db(
      { skip, limit, sortInfo },
      keywords,
      start,
      end,
      selectedCountries,
      selectedWebsites,
      setError
    );
    setTotal_pages(Math.ceil(results.count / limit));
    setTotal_articles(results.count);
    setCards_data(results.data);
  };

  const hkeyChange = (e) => {
    setKeywords(e.target.value);
  };

  if (!AuthService.getCurrentUser()) {
    history.push("/login");
    window.location.reload();
  }

  return (
    <Container className="cards-list">
      <Header style={{ color: "white" }}>Articles Search</Header>
      <Header as="h6" style={{ color: "white" }}>
        <div className="content">
          <button
            className="btn btn-primary"
            onClick={() => setOpen((o) => !o)}
          >
            <BsFilter />
            &nbsp;&nbsp;Filter&nbsp;&nbsp;
          </button>

          <Popup
            open={open}
            className="popup"
            closeOnDocumentClick={false}
            position="right center"
            onClose={onFilter}
          >
            <a className="close" onClick={closeModal}>
              &times;
            </a>

            <div class="form-group row custom-group">
              <form>
                <label>Date Range</label>
                <DateRangePicker
                  initialSettings={{
                    startDate: start.toDate(),
                    endDate: end.toDate(),
                    ranges: {
                      Today: [moment().toDate(), moment().toDate()],
                      Yesterday: [
                        moment().subtract(1, "days").toDate(),
                        moment().subtract(1, "days").toDate(),
                      ],
                      "Last 7 Days": [
                        moment().subtract(6, "days").toDate(),
                        moment().toDate(),
                      ],
                      "Last 30 Days": [
                        moment().subtract(29, "days").toDate(),
                        moment().toDate(),
                      ],
                      "This Month": [
                        moment().startOf("month").toDate(),
                        moment().endOf("month").toDate(),
                      ],
                      "Last Month": [
                        moment().subtract(1, "month").startOf("month").toDate(),
                        moment().subtract(1, "month").endOf("month").toDate(),
                      ],
                    },
                  }}
                  onCallback={handleCallback}
                >
                  <div id="reportrange" className="form-control cal-input">
                    <BsCalendar />
                    &nbsp;&nbsp;<span>{label}</span>
                  </div>
                </DateRangePicker>
                <br />
                <br />
                <div class="form-group row">
                  <label>Websites</label>
                      <ReactMultiSelectCheckboxes
                        className="multi-select"
                        id="websites"
                        defaultValue={selectedWebsites}
                        onChange={setSelectedWebsites}
                        options={website_list}
                      />
                </div>
                <div class="form-group row">
                  <label>Countries</label>
                  <ReactMultiSelectCheckboxes
                    className="multi-select"
                    id="countries"
                    defaultValue={selectedCountries}
                    onChange={setSelectedCountries}
                    options={countries_list}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-primary col-md-12 text-center"
                  onClick={closeModal}
                >
                  Filter Results
                </button>
              </form>
            </div>
          </Popup>
          <br />
          <br />
          <br />

          <form
            style={{ display: "inline-flex", justifyContent: "center" }}
            onSubmit={onFormSubmit}
          >
            <input
              class="form-control"
              style={{ minWidth: "50vw" }}
              value={keywords}
              onChange={(e) => hkeyChange(e)}
              type="text"
              placeholder="Enter Text"
            />
            &nbsp;
            <button type="submit" className="btn btn-primary btn-sm">
              Search
            </button>
          </form>
        </div>
        <br />
        <br />
      </Header>
      <br />
      <Container style={{ display: total_articles === 0 ? "none" : "block" }}>
        <div>Found {total_articles} articles.</div>
        {paginator(activePage, total_pages, cards_data)}
        {cards_data.map((data) => (
          <ACard card_data={data} keywords={keywords} />
        ))}
        {paginator(activePage, total_pages, total_articles)}
        <br />
      </Container>
    </Container>
  );
};

export default CardsView;
