import authHeader from "../services/auth-header";

const handleDate = (value) => {
  var d = new Date();
  d.setTime(Number(value));
  if (d.toString().includes("Date")) return "";
  else return d.toString().slice(3, 21).trim();
};

// '{"start":{"$date":1642308007053},"elapsed":133.157936,"items":9,"pages":10,"errors":0}'

const extractStats = (stats) => {
  if (stats === undefined) return ''
  let data = {
    Start: handleDate(stats?.start.$date),
    Elapsed: stats?.elapsed,
    Pages: stats?.pages,
    Items: stats?.items,
    Errors: stats?.errors
  }
    
  return data
  
}

const query_db = async () => {
    // alert(JSON.stringify(sortInfo.name))
  
    const base_url = "https://admin-api.infobring.io/";
  
    let url = base_url + "spiders/ ";
    let rows = await fetch(url, { 
      // mode: 'cors',
      // credentials: 'include',
      method: "GET", 
      headers: authHeader() 
    })
      .then((response) => response.json())
      .then((data) => {
        // alert(JSON.stringify(data))
        if (data.error !== undefined) {
          // setError(data.error);
          data = [];
          return data;
        }
        if (data.spiders === undefined) {
          let data = [];
          return data;
        }
        
      
        // alert(JSON.stringify(data))
        console.log(data.spiders[3].stats.elapsed);

        data = data.spiders.map((spider) => ({
          id: spider._id.$oid,
          name: spider.name,
          homepage: spider.homepage,
          description: spider.description,
          timezone: spider.timezone,
          base_url: spider.settings.base_url,
          stats: extractStats(spider.stats),
          active: spider.settings.active,
        }));
        // console.log(JSON.stringify(data));
        return data;
      });
      return rows;
  };
  
  export default query_db;