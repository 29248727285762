import React, { useState, useEffect, useCallback } from "react";
import { Chart, Line } from "bizcharts";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { BsCalendar } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import authHeader from "../services/auth-header";
import AuthService from "../services/auth.service";

import "@inovua/reactdatagrid-community/index.css";

const handleStartDate = (value) => {
  var d = new Date(value); // Directly passing the ISO string to the Date constructor
  if (isNaN(d.getTime())) return ""; // Check if the date is invalid
  else return d.toString().slice(3, 21).trim();
};


const gridStyle = { minHeight: 400, width: "160vh" };

const defaultFilterValue = [
  { name: 'base_url', operator: 'startsWith', type: 'string', value: '' },
  { name: 'log', operator: 'startsWith', type: 'string', value: '' },
  { name: 'bot', operator: 'startsWith', type: 'string', value: '' },
  { name: 'start', operator: 'startsWith', type: 'string', value: '' },
  { name: 'elapsed', operator: 'gte', type: 'number', value: 0 },
  { name: 'pages', operator: 'gte', type: 'number', value: 0 },
  { name: 'items', operator: 'gte', type: 'number', value: 0 },
  { name: 'errors', operator: 'gte', type: 'number', value: 0 },
];

const columns = [
  { name: "id", header: "Id", defaultVisible: false },
  {
    name: "base_url",
    header: "Base URL",
    sortable: true,
    defaultFlex: 2,
  },
  { name: "log", header: "Log", sortable: true, defaultFlex: 1.5 },
  {
    name: "bot",
    header: "Bot",
    sortable: true,
    defaultFlex: 1.5,
  },
  {
    name: "start",
    header: "Last Run Date",
    sortable: true,
    defaultFlex: 1.5,
  },
  { name: "elapsed", header: "Time Elapsed", defaultFlex: 1 },
  { name: "pages", header: "Pages", defaultFlex: 0.6 },
  { name: "items", header: "Items", defaultFlex: 0.6 },
  { name: "errors", header: "Errors", defaultFlex: 0.6 },
];


const query_db = (
  { skip, limit, sortInfo },
  start,
  end,
  filterValue
) => {
  //   alert(JSON.stringify(limit))

  const base_url = "https://admin-api.infobring.io/jobs_activity";

  let url =
    base_url +
    "/skip/" +
    skip +
    "/limit/" +
    limit +
    "/filter/" +
    JSON.stringify(filterValue) +
    "?min_date=" +
    handleDate(start) +
    "&max_date=" +
    handleDate(end);

  if (sortInfo !== null && sortInfo !== undefined)
    url += "?sort_field=" + sortInfo.name + "?sort_dir=" + sortInfo.dir;

  return fetch(url, {
    // mode: 'cors',
    // credentials: 'include',
    method: "GET",
    headers: authHeader()
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      if (data.error !== undefined) {
        data = [];
        let totalCount = 0;
        return Promise.resolve({ data, count: parseInt(totalCount) });
      }
      if (
        data.results === undefined ||
        data.results[0].metadata === undefined ||
        data.results[0].metadata.length === 0
      ) {
        let data = [];
        let totalCount = 0;
        return Promise.resolve({ data, count: parseInt(totalCount) });
      }
      let totalCount = data.results[0].metadata[0].total;
      data = data.results[0].data.map((result) => ({
        id: result._id.$oid,
        log: result.log_url,
        start: handleStartDate(result.start.$date),
        base_url: result.base_url,
        bot: result.bot,
        elapsed: Math.round(result.elapsed).toString() + 's',
        items: result.items,
        pages: result.pages,
        errors: result.errors
      }));
      // alert(JSON.stringify(data))
      return Promise.resolve({ data, count: parseInt(totalCount) });
    });
};


const handleDate = (value) => {
  var d = new Date();
  d.setTime(Number(value));
  return d.toString().slice(3, 15).trim();
};

const Monitor = () => {
  let history = useHistory()
  const [articles, setArticles] = useState([]);
  const [missing, setMissing] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterValue, setFilterValue] = useState(defaultFilterValue);
  const [state, setDate] = useState({
    start: moment().subtract(60, "days"),
    end: moment().add(1, "days"),
  });
  const { start, end } = state;
  const handleCallback = (start, end) => {
    setDate({ start, end });
  };
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const dataSource = useCallback(query_db, [
    start,
    end,
  ]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const fetch_data = async () => {
    const url =
      "https://admin-api.infobring.io/counts_per_day/?start=" + handleDate(start) + "&end=" + handleDate(end);
    setLoading(true);
    await fetch(url, {
      // mode: 'cors',
      // credentials: 'include',
      method: "GET",
      headers: authHeader()
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.results) {
          //   alert(JSON.stringify(data));
          let articles = [];
          let missing = [];
          data.results.articles.map((result) => {
            articles.push({ day: result._id, articles: result.count });
          });
          data.results.missing.map((result) => {
            missing.push({ day: result._id, missing: result.count });
          });
          setArticles(articles);
          setMissing(missing);
          setLoading(false);
        }
      })
      .catch((error) => {
        setArticles([]);
        setLoading(false);
        console.log(error);
      });
  };

  const onCellClick = useCallback((event, cellProps) => {
    const { columnIndex, rowIndex, value } = cellProps;

    const redirectLink = (c, r) => {
      if (c === 1) {
        window.open(value, "_blank", "toolbar=0,location=0,menubar=0");
      }
    };
    redirectLink(columnIndex, rowIndex);
  }, []);

  useEffect(() => {
    fetch_data();
    return () => {
      return [];
    };
  }, [start, end]);

  let isMobile = width <= 768;

  let label = "";

  if (isMobile === false)
    label = start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY");
  else label = "";

  if (!AuthService.getCurrentUser()) {
    history.push("/login");
    window.location.reload();
  }
  return (
    <>
      <div className="report-header" style={{
        display: loading ? "none" : "block",
        marginTop: "50px"
      }}>
        <h2>Jobs Monitor</h2>

        {/* <iframe
          id="my-frame"
          src="http://95.217.44.158/kibana/app/dashboards?auth_provider_hint=anonymous1#/create?embed=true&_g=(filters:!(),query:(language:kuery,query:''),refreshInterval:(pause:!t,value:0),time:(from:now-5d,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,syncColors:!f,useMargins:!t),panels:!((embeddableConfig:(),gridData:(h:15,i:'8dfe6d1e-128c-4aad-8eba-6b5876adc555',w:24,x:0,y:0),id:de065140-ade7-11ed-a905-1fee5320fef2,panelIndex:'8dfe6d1e-128c-4aad-8eba-6b5876adc555',type:lens,version:'7.17.7')),query:(language:kuery,query:''),tags:!(),timeRestore:!f,title:'',viewMode:edit)&show-time-filter=true"
          height="600"
          width="800"></iframe> */}
        <DateRangePicker
          initialSettings={{
            startDate: start.toDate(),
            endDate: end.toDate(),
            ranges: {
              Today: [moment().toDate(), moment().toDate()],
              Yesterday: [
                moment().subtract(1, "days").toDate(),
                moment().subtract(1, "days").toDate(),
              ],
              "Last 7 Days": [
                moment().subtract(6, "days").toDate(),
                moment().toDate(),
              ],
              "Last 30 Days": [
                moment().subtract(29, "days").toDate(),
                moment().toDate(),
              ],
              "This Month": [
                moment().startOf("month").toDate(),
                moment().endOf("month").toDate(),
              ],
              "Last Month": [
                moment().subtract(1, "month").startOf("month").toDate(),
                moment().subtract(1, "month").endOf("month").toDate(),
              ],
              "Last 3 Month": [
                moment().subtract(3, "month").startOf("month").toDate(),
                moment().subtract(1, "month").endOf("month").toDate(),
              ],
            },
          }}
          onCallback={handleCallback}
        >
          <div
            id="reportrange"
            className="form-control cal-input"
            aria-label="Small"
          >
            <BsCalendar />
            &nbsp;&nbsp;<span>{label}</span>
          </div>
        </DateRangePicker>

      </div>
      <div
        class="text-center"
        style={{
          color: "rgba(255, 146, 255, 1)",
          paddingTop: "50px",
          display: loading ? "block" : "none",
        }}
      >
        <div class="spinner-border" role="status"></div>
      </div>

      <div
        style={{
          display: loading ? "none" : "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <Chart
          scale={{ value: { min: 0 } }}
          padding={[10, 20, 50, 40]}
          autoFit
          height="40vh"
          width="40vw"
          data={articles}
        >
          <Line
            shape="smooth"
            position="day*articles"
            color="l (270) 0:rgba(248, 32, 32, 1) .5:rgba(255, 251, 2, 1) 1:rgba(67, 241, 14, 1)"
          />
          <p style={{ fontSize: "smaller", textAlign: "center" }}>
            # of Articles
          </p>
        </Chart>
        <div style={{ width: "5vw" }}></div>
        <Chart
          scale={{ value: { min: 0 } }}
          padding={[10, 20, 50, 40]}
          autoFit
          height="40vh"
          width="40vw"
          data={missing}
        >
          <Line
            shape="smooth"
            position="day*missing"
            color="l (270) 0:rgba(67, 241, 14, 1) .5:rgba(255, 251, 2, 1) 1:rgba(248, 32, 32, 1)"
          />
          <p style={{ fontSize: "smaller", textAlign: "center" }}>
            Missing fields
          </p>
        </Chart>
      </div>
      <div style={{ flex: 'None', display: loading ? "none" : "block" }}>
        <br />
        <br />
        <br />
        <h2>Jobs Activity</h2>
        <ReactDataGrid
          idProperty="id"
          style={gridStyle}
          checkboxOnlyRowSelect={true}
          columns={columns}
          pagination={"remote"}
          dataSource={(d) =>
            dataSource(
              d,
              start,
              end,
              filterValue
            )
          }
          defaultFilterValue={filterValue}
          onFilterValueChange={setFilterValue}
          defaultLimit={10}
          sortable={false}
          editable={false}
          onCellClick={onCellClick}
        // rtl={true}
        />
        <br />
        <br />
      </div>

    </>
  );
};

export default Monitor;
