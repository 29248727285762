/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { useParams, Prompt } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import query_spider from "./GetSpider";
import form_template from "./template_data";
import Popup from "reactjs-popup";
import TestResult from "./TestResults";
import authHeader from "../services/auth-header";

const deleteSpider = async (id) => {
  const base_url = "https://admin-api.infobring.io/";
  let url = base_url + "spider/" + id;
  const delMethod = {
    method: "DELETE", // Method itself
    headers: authHeader(),
  };
  await fetch(url, delMethod)
    .then((response) => response.json())
    .then((data) => {
      if (data.result === "success") {
        alert("Spider archived successfully...");
        window.location = "/settings";
      } else {
        alert("Failed to archive spider...");
      }
    })
    .catch((err) => {
      alert("Failed to archive spider: " + err);
    });
};

const update_db = async (id, data, handleUpdateResult) => {
  const base_url = "https://admin-api.infobring.io/";
  let url = base_url + "spider/" + id;
  // let headers = authHeader();
  // data.stats = {}
  // console.log(JSON.stringify(data));
  // headers.append('Content-type', 'application/json; charset=UTF-8');
  const putMethod = {
    method: "PUT", // Method itself
    headers: authHeader(),
    body: JSON.stringify(data), // We send data in JSON format
  };
  await fetch(url, putMethod)
    .then((response) => response.json())
    .then((data) => {
      handleUpdateResult(data.result, data.id);
    })
    .catch((err) => handleUpdateResult(err, 0));
};

const getSpider = (id) => {
  const [olddata, setoldData] = useState(null);
  async function fetchData() {
    if (id !== "new") {
      const d = await query_spider(id);
      setoldData(d);
    } else {
      setoldData(form_template);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  return olddata;
};

const Spider = () => {
  const [jobid, setJobID] = useState("");
  const [log_url, setLogURL] = useState("");
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const { id } = useParams();
  let oldFetchedData = getSpider(id);
  console.log(JSON.stringify(oldFetchedData))
  const [formData, setData] = useState(null);
  const [dataModified, setDataModified] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saving_error, setSavingError] = useState(false);
  const [name, setName] = useState("");
  const [bot_type, setBot_type] = useState("");
  const [homepage, setHomepage] = useState("");
  const [description, setDescription] = useState("");
  const [timezone, setTimezone] = useState("");
  const [country, setCountry] = useState("");
  const [language, setLanguage] = useState("");
  const [url, setUrl] = useState("");
  const [article_list, setArticle_list] = useState("");
  const [title, setTitle] = useState("");
  const [news_type, setNews_type] = useState("");
  const [published, setPublished] = useState("");
  const [article_url, setArticle_url] = useState("");
  const [image_url, setImage_url] = useState("");
  const [article_text, setArticle_text] = useState("");
  const [next_page, setNext_page] = useState("");
  const [active, setActive] = useState("");
  const [schedule, setSchedule] = useState("");
  const [history, setHistory] = useState("");
  const [enable_js, setJavascript] = useState("");
  const [enable_cs, setCloudScraper] = useState("");
  const [enable_uc, setUndetected] = useState("");
  const [enable_cookies, setCookies] = useState("");
  const [enable_go, setGoLogin] = useState("");
  const [content_playwright, setContent_playwright] = useState(false);
  const [content_gologin, setContent_gologin] = useState(false);
  const [enable_zenscrape, setZenscrape] = useState("");
  const [rotate_proxies, setProxies] = useState("");
  const [data_center, setData_center] = useState("");
  const [titles_found_maxcount, setTitles_found_maxcount] = useState("");
  const [links_limit, setLinks_limit] = useState("");
  const [rss, setRSS] = useState("");
  const [base_url, setBase_url] = useState("");
  const [headers, setHeaders] = useState("");
  const [cookies, setCookiesText] = useState("{}");
  const [refine_regex, setRefine_regex] = useState("");
  const [load_more, setLoad_more] = useState("");
  const [ignore_date, setIgnore_date] = useState(false);
  

  useEffect(() => {
    if (formData && formData !== undefined) {
      setName(formData.name);
      setDescription(formData.description);
      setHomepage(formData.homepage);
      setCountry(formData.country);
      setLanguage(formData.language);
      setTimezone(formData.timezone);
      setUrl(JSON.stringify(formData.settings.url));
      setArticle_list(JSON.stringify(formData.settings.articles_list));
      setTitle(JSON.stringify(formData.settings.title));
      setNews_type(JSON.stringify(formData.settings.news_type));
      setPublished(JSON.stringify(formData.settings.published));
      setArticle_url(JSON.stringify(formData.settings.article_url));
      setArticle_text(JSON.stringify(formData.settings.text));
      setNext_page(JSON.stringify(formData.settings.next_page));
      setImage_url(JSON.stringify(formData.settings.image_url));
      setLoad_more(JSON.stringify(formData.settings.load_more));
      setActive(formData.settings.active);
      setSchedule(formData.settings.schedule);
      setHistory(formData.settings.history);
      setJavascript(formData.settings.enable_js);
      setCloudScraper(formData.settings.enable_cs);
      setUndetected(formData.settings.enable_uc);
      setCookies(formData.settings.enable_cookies);
      setGoLogin(formData.settings.enable_go);
      setContent_playwright(formData.settings.content_playwright);
      setContent_gologin(formData.settings.content_gologin);
      setZenscrape(formData.settings.enable_zenscrape);
      setProxies(formData.settings.rotate_proxies);
      setData_center(formData.settings.data_center);
      setTitles_found_maxcount(formData.settings.titles_found_maxcount);
      setLinks_limit(formData.settings.links_limit);
      setRSS(formData.settings.rss);
      setBase_url(formData.settings.base_url);
      setHeaders(JSON.stringify(formData.settings.headers));
      setCookiesText(JSON.stringify(formData.settings.cookies));
      setRefine_regex(formData.settings.refine_regex);
      setIgnore_date(formData.settings.ignore_date);
    }
  }, [formData]);

  const handleUpdateResult = (result, new_id) => {
    setSaving(false);
    if (result === "success") {
      setDataModified(false);
      setSavingError("");
      oldFetchedData = formData;
      window.location = "/spider/" + new_id;
    } else if (result === "failure") {
      setSavingError("Failed to save data...");
    } else {
      setSavingError("Failed to save data: " + result);
    }
  };

  const handleTest = async (bot_type) => {
    setOpen(true);
    setJobID(""); 
    setLogURL("");
    setBot_type(bot_type);
    const base_url = "https://admin-api.infobring.io/";
    let url = base_url + "test/" + id + "/" + bot_type;
      await fetch(url, { 
        // mode: 'cors',
        // credentials: 'include',
        method: "GET", 
        headers: authHeader() 
      })
        .then((response) => response.json())
        .then((data) => {
          // alert(JSON.stringify(data));
          // alert(data.log_url)
          setJobID(data.jobid);
          setLogURL(data.log_url)
        });
      // .catch((err) => alert(JSON.stringify(err)));
  };

  const handleSave = () => {
    setSaving(true);
    update_db(id, formData, handleUpdateResult);
  };

  const handleChange = (e) => {
    setSavingError("");
    const value = e.target.value;
    let newData = formData;
    try {
      if (e.target.id === "name") {
        setName(value);
        newData.name = value;
      }
      if (e.target.id === "description") {
        setDescription(value);
        newData.description = value;
      }
      if (e.target.id === "homepage") {
        setHomepage(value);
        newData.homepage = value;
      }
      if (e.target.id === "country") {
        setCountry(value);
        newData.country = value;
      }
      if (e.target.id === "language") {
        setLanguage(value);
        newData.language = value;
      }
      if (e.target.id === "timezone") {
        setTimezone(value);
        newData.timezone = value;
      }
      if (e.target.id === "url") {
        setUrl(value);
        newData.settings.url = JSON.parse(value);
      }
      if (e.target.id === "articles_list") {
        setArticle_list(value);
        newData.settings.articles_list = JSON.parse(value);
      }
      if (e.target.id === "title") {
        setTitle(value);
        newData.settings.title = JSON.parse(value);
      }
      if (e.target.id === "news_type") {
        setNews_type(value);
        newData.settings.news_type = JSON.parse(value);
      }
      if (e.target.id === "published") {
        setPublished(value);
        newData.settings.published = JSON.parse(value);
      }
      if (e.target.id === "article_url") {
        setArticle_url(value);
        newData.settings.article_url = JSON.parse(value);
      }
      if (e.target.id === "image_url") {
        setImage_url(value);
        newData.settings.image_url = JSON.parse(value);
      }
      if (e.target.id === "load_more") {
        setLoad_more(value);
        newData.settings.load_more = JSON.parse(value);
      }
      if (e.target.id === "article_text") {
        setArticle_text(value);
        newData.settings.text = JSON.parse(value);
      }
      if (e.target.id === "next_page") {
        setNext_page(value);
        newData.settings.next_page = JSON.parse(value);
      }
      if (e.target.id === "active") {
        setActive(value);
        newData.settings.active = JSON.parse(value);
      }
      if (e.target.id === "schedule") {
        setSchedule(value);
        newData.settings.schedule = value;
      }
      if (e.target.id === "history") {
        setHistory(value);
        newData.settings.history = JSON.parse(value);
      }
      if (e.target.id === "enable_js") {
        setJavascript(value);
        newData.settings.enable_js = JSON.parse(value);
      }
      if (e.target.id === "enable_cs") {
        setCloudScraper(value);
        newData.settings.enable_cs = JSON.parse(value);
      }
      if (e.target.id === "enable_uc") {
        setUndetected(value);
        newData.settings.enable_uc = JSON.parse(value);
      }
      if (e.target.id === "enable_cookies") {
        setCookies(value);
        newData.settings.enable_cookies = JSON.parse(value);
      }
      if (e.target.id === "enable_go") {
        setGoLogin(value);
        newData.settings.enable_go = JSON.parse(value);
      }
      if (e.target.id === "content_playwright") {
        setContent_playwright(value);
        newData.settings.content_playwright = JSON.parse(value);
      }
      if (e.target.id === "content_gologin") {
        setContent_gologin(value);
        newData.settings.content_gologin = JSON.parse(value);
      }
      if (e.target.id === "enable_zenscrape") {
        setZenscrape(value);
        newData.settings.enable_zenscrape = JSON.parse(value);
      }
      if (e.target.id === "rotate_proxies") {
        setProxies(value);
        newData.settings.rotate_proxies = value;
      }
      if (e.target.id === "data_center") {
        setData_center(value);
        newData.settings.data_center = JSON.parse(value);
      }
      if (e.target.id === "titles_found_maxcount") {
        setTitles_found_maxcount(value);
        newData.settings.titles_found_maxcount = JSON.parse(value);
      }
      if (e.target.id === "links_limit") {
        setLinks_limit(value);
        newData.settings.links_limit = JSON.parse(value);
      }
      if (e.target.id === "rss") {
        setRSS(value);
        newData.settings.rss = JSON.parse(value);
      }
      if (e.target.id === "base_url") {
        setBase_url(value);
        newData.settings.base_url = value;
      }
      if (e.target.id === "headers") {
        setHeaders(value);
        newData.settings.headers = JSON.parse(value);
      }
      if (e.target.id === "refine_regex") {
        setRefine_regex(value);
        newData.settings.refine_regex = value;
      }
      if (e.target.id === "cookies") {
        setCookiesText(value);
        newData.settings.cookies = JSON.parse(value);
      }
      
      if (e.target.id === "ignore_date") {
        setIgnore_date(value);
        newData.settings.ignore_date = JSON.parse(value);
      }
    } catch (error) {
      document.getElementById(e.target.id + "_error").className =
        "invalid-json";
      setDataModified(false);
      return;
    }
    document.getElementById(e.target.id + "_error").className =
      "invalid-feedback";
    setData(newData);
    if (JSON.stringify(newData) !== JSON.stringify(oldFetchedData)) {
      setDataModified(true);
    } else {
      setDataModified(false);
    }
  };

  if (!formData) {
    if (oldFetchedData) {
      setData(JSON.parse(JSON.stringify(oldFetchedData)));
    }
    return <div>Loading...</div>;
  } else {
    return (
      <div className="maincontainer">
        <div className="container modal-body row-fluid">
          <div className="py-5 text-center">
            <h1>Spider Settings</h1>
          </div>
          <div className="col-lg-11">
            <div className="vertical-line"></div>
            <div className="row">
              <div className="col-md-11 order-md-1">
                <h3 className="mb-3">Basic</h3>
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    event.target.reset();
                  }}
                >
                  <Prompt
                    when={dataModified}
                    message="There are unsaved changes, do you wish to discard them?"
                  />
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="name">Spider Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder=""
                          value={name}
                          required
                          onChange={handleChange}
                        />
                        <div id="name_error" className="invalid-feedback">
                          Invalid!
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="homepage">Homepage</label>
                        <input
                          type="text"
                          className="form-control"
                          id="homepage"
                          placeholder=""
                          value={homepage}
                          required
                          onChange={handleChange}
                        />
                        <div id="homepage_error" className="invalid-feedback">
                          Invalid!
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <label htmlFor="country">Country</label>
                        <input
                          type="text"
                          className="form-control"
                          id="country"
                          placeholder=""
                          value={country}
                          required
                          onChange={handleChange}
                        />
                        <div id="country_error" className="invalid-feedback">
                          Invalid!
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="timezone">TimeZone</label>
                        <input
                          type="text"
                          className="form-control"
                          id="timezone"
                          placeholder=""
                          value={timezone}
                          required
                          onChange={handleChange}
                        />
                        <div id="timezone_error" className="invalid-feedback">
                          Invalid!
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="language">Language</label>
                        <input
                          type="text"
                          className="form-control"
                          id="language"
                          placeholder=""
                          value={language}
                          required
                          onChange={handleChange}
                        />
                        <div id="language_error" className="invalid-feedback">
                          Invalid!
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="description">Description</label>
                      <span className="text-muted"> (Optional)</span>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="description"
                          placeholder="Description"
                          value={description}
                          onChange={handleChange}
                        />
                        <div
                          id="description_error"
                          className="invalid-feedback"
                        >
                          Invalid!
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3 className="mb-3">General Settings</h3>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-2 mb-3">
                        <label htmlFor="active">Active</label>
                        <input
                          type="text"
                          className="form-control"
                          id="active"
                          value={active}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div id="active_error" className="invalid-feedback">
                          Invalid!
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <label htmlFor="scheduele">Schedule</label>
                        <input
                          type="text"
                          className="form-control"
                          id="schedule"
                          value={schedule}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div id="schedule_error" className="invalid-feedback">
                          Invalid!
                        </div>
                      </div>
                      <div className="col-md-1 mb-3">
                        <label htmlFor="history">History</label>
                        <input
                          type="text"
                          className="form-control"
                          id="history"
                          value={history}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div id="history_error" className="invalid-feedback">
                          Invalid!
                        </div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <label htmlFor="enable_js">Javascript</label>
                        <input
                          type="text"
                          className="form-control"
                          id="enable_js"
                          value={enable_js}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div id="enable_js_error" className="invalid-feedback">
                          Invalid!
                        </div>
                        <div className="invalid-feedback"></div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <label htmlFor="content_playwright">With Content</label>
                        <input
                          type="text"
                          className="form-control"
                          id="content_playwright"
                          value={content_playwright}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div id="content_playwright_error" className="invalid-feedback">
                          Invalid!
                        </div>
                        <div className="invalid-feedback"></div>
                      </div>
                      {/* <div className="col-md-2 mb-3">
                        <label htmlFor="enable_zenscrape">Zenscrape</label>
                        <input
                          type="text"
                          className="form-control"
                          id="enable_zenscrape"
                          value={enable_zenscrape}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div
                          id="enable_zenscrape_error"
                          className="invalid-feedback"
                        >
                          Invalid!
                        </div>
                        <div className="invalid-feedback"></div>
                      </div> */}
                      <div className="col-md-6 mb-3">
                        <label htmlFor="rotate_proxies">Residential</label>
                        <input
                          type="text"
                          className="form-control"
                          id="rotate_proxies"
                          value={rotate_proxies}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div
                          id="rotate_proxies_error"
                          className="invalid-feedback"
                        >
                          Invalid!
                        </div>
                        <div className="invalid-feedback"></div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <label htmlFor="data_center">Data Center</label>
                        <input
                          type="text"
                          className="form-control"
                          id="data_center"
                          value={data_center}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div
                          id="data_center_error"
                          className="invalid-feedback"
                        >
                          Invalid!
                        </div>
                        <div className="invalid-feedback"></div>
                      </div>
                      {/* <div className="col-md-2 mb-3">
                        <label htmlFor="enable_cs">Cloud Scraper</label>
                        <input
                          type="text"
                          className="form-control"
                          id="enable_cs"
                          value={enable_cs}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div id="enable_cs_error" className="invalid-feedback">
                          Invalid!
                        </div>
                        <div className="invalid-feedback"></div>
                      </div> */}
                      {/* <div className="col-md-2 mb-3">
                        <label htmlFor="enable_uc">Undetected Chrome</label>
                        <input
                          type="text"
                          className="form-control"
                          id="enable_uc"
                          value={enable_uc}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div id="enable_uc_error" className="invalid-feedback">
                          Invalid!
                        </div>
                        <div className="invalid-feedback"></div>
                      </div> */}
                      <div className="col-md-2 mb-3">
                        <label htmlFor="enable_cookies">Cookies</label>
                        <input
                          type="text"
                          className="form-control"
                          id="enable_cookies"
                          value={enable_cookies}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div id="enable_cookies_error" className="invalid-feedback">
                          Invalid!
                        </div>
                        <div className="invalid-feedback"></div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <label htmlFor="enable_go">GoLogin</label>
                        <input
                          type="text"
                          className="form-control"
                          id="enable_go"
                          value={enable_go}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div id="enable_go_error" className="invalid-feedback">
                          Invalid!
                        </div>
                        <div className="invalid-feedback"></div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <label htmlFor="content_gologing">With Content</label>
                        <input
                          type="text"
                          className="form-control"
                          id="content_gologin"
                          value={content_gologin}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div id="content_gologin_error" className="invalid-feedback">
                          Invalid!
                        </div>
                        <div className="invalid-feedback"></div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <label htmlFor="ignore_date">Ignore Date</label>
                        <input
                          type="text"
                          className="form-control"
                          id="ignore_date"
                          value={ignore_date}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div id="ignore_date_error" className="invalid-feedback">
                          Invalid!
                        </div>
                        <div className="invalid-feedback"></div>
                      </div>
                      <div className="col-md-2 mb-3">
                        <label htmlFor="links_limit">Links Limit</label>
                        <input
                          type="text"
                          className="form-control"
                          id="links_limit"
                          value={links_limit}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div id="links_limit_error" className="invalid-feedback">
                          Invalid!
                        </div>
                        <div className="invalid-feedback"></div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <label htmlFor="titles_found_maxcount">
                          Links Found
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="titles_found_maxcount"
                          value={titles_found_maxcount}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div
                          id="titles_found_maxcount_error"
                          className="invalid-feedback"
                        >
                          Invalid!
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <label htmlFor="rss">RSS</label>
                        <input
                          type="text"
                          className="form-control"
                          id="rss"
                          value={rss}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div id="rss_error" className="invalid-feedback">
                          Invalid!
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="base_url">
                          Base URL
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="base_url"
                          value={base_url}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div
                          id="base_url_error"
                          className="invalid-feedback"
                        >
                          Invalid!
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="headers">
                          Custom Headers
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="headers"
                          value={headers}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div
                          id="headers_error"
                          className="invalid-feedback"
                        >
                          Invalid!
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="refine_regex">
                          Refine Text
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="refine_regex"
                          value={refine_regex}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div
                          id="refine_regex_error"
                          className="invalid-feedback"
                        >
                          Invalid!
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="cookies">
                          Custom Cookies
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="cookies"
                          value={cookies}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div
                          id="cookies_error"
                          className="invalid-feedback"
                        >
                          Invalid!
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="load_more">
                          Load More Settings
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="load_more"
                          value={load_more}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div
                          id="load_more_error"
                          className="invalid-feedback"
                        >
                          Invalid!
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3 className="mb-3">Scrapy Settings</h3>
                  <div className="form-group">
                    <div className="row">
                    <div className="mb-3">
                        <label htmlFor="url">Url</label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="url"
                          value={url}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div
                          id="url_error"
                          className="invalid-feedback"
                        >
                          Invalid!
                        </div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="article_list">Articles List</label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="articles_list"
                          value={article_list}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div
                          id="articles_list_error"
                          className="invalid-feedback"
                        >
                          Invalid!
                        </div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="title">Title</label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="title"
                          value={title}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div id="title_error" className="invalid-feedback">
                          Invalid!
                        </div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="news_type">News Type</label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="news_type"
                          value={news_type}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div id="news_type_error" className="invalid-feedback">
                          Invalid!
                        </div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="published">Published Date</label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="published"
                          value={published}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div id="published_error" className="invalid-feedback">
                          Invalid!
                        </div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="article_url">Article Link</label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="article_url"
                          value={article_url}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div
                          id="article_url_error"
                          className="invalid-feedback"
                        >
                          Invalid!
                        </div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="image_url">Image URL</label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="image_url"
                          value={image_url}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div id="image_url_error" className="invalid-feedback">
                          Invalid!
                        </div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="text">Article Text</label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="article_text"
                          value={article_text}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div
                          id="article_text_error"
                          className="invalid-feedback"
                        >
                          Invalid!
                        </div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="next_page">Next Page</label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="next_page"
                          value={next_page}
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <div
                          id="next_page_error"
                          className="invalid-feedback"
                        >
                          Invalid!
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-1 sticky">
            <h3 className="mb-3">Actions</h3>
            <div className="action-panel">
              <button
                disabled={dataModified}
                onClick={() => handleTest("Link Extractor")}
                type="button"
                className="btn btn-primary btn-lg mb-3"
              >
                Test Links
              </button>
              <button
                disabled={dataModified}
                onClick={() => handleTest("Content Bot")}
                type="button"
                className="btn btn-primary btn-lg mb-3"
              >
                Test Content
              </button>
              <Popup
                open={open}
                position="right center"
                closeOnDocumentClick
                onClick={closeModal}
                onClose={() => setOpen(false)}
              >
                <TestResult homepage={homepage} jobid={jobid} log_url={log_url} bot_type={bot_type} closeModal={closeModal} />
              </Popup>

              <button
                onClick={(e) => {
                  if (
                    window.confirm(
                      "Are you sure you wish to archive this spider?"
                    )
                  )
                    deleteSpider(id);
                }}
                style={{ display: id === "new" ? "none" : "block" }}
                type="button"
                className="btn btn-danger btn-lg mb-3"
              >
                Archive Spider
              </button>
              <button
                className="btn btn-primary btn-lg"
                type="button"
                disabled={!dataModified}
                onClick={handleSave}
              >
                {saving ? "Saving..." : "Save"}
              </button>
              <div
                style={{ color: "red", marginTop: "5px", fontSize: "0.9em" }}
              >
                {saving_error}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Spider;
