import React, { useState, useCallback, useEffect } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { BsCalendar, BsFilter } from "react-icons/bs";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { useHistory } from "react-router-dom";
import authHeader from "../services/auth-header";
import AuthService from "../services/auth.service";

import "@inovua/reactdatagrid-community/index.css";

import "bootstrap/dist/css/bootstrap.css";

const status_fields = [
  { label: "New", value: "new" },
  { label: "Done", value: "done" },
  { label: "Error", value: "error" },
];


const get_websites = async () => {
    const base_url = "https://admin-api.infobring.io";
    const url = base_url + "/websites";
    return fetch(url, { 
      // mode: 'cors',
      // credentials: 'include',
      method: "GET", 
      headers: authHeader()
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error === undefined) {
          data = data.websites.map((result) => ({
            label: result.name, 
            value: result.name 
          }))
          return data;
        }

      })   
}

const handleDate = (value) => {
  var d = new Date();
  d.setTime(Number(value));
  if (d.toString().includes("Date")) return "";
  else return d.toString().slice(3, 15).trim();
};

const toArray = (items) => Object.keys(items).map((id) => id);

const columns = [
  { name: "id", header: "Id", defaultVisible: false },
  {
    name: "scraped",
    header: "Scraped Date",
    sortable: true,
    defaultFlex: 0.6,
  },
  {
    name: "published",
    header: "Published Date",
    sortable: true,
    defaultFlex: 0.6,
  },
  { name: "homepage", header: "Website", defaultFlex: 1},
  { name: "url", header: "Link", defaultFlex: 2.5 },
  { name: "status", header: "Status", defaultFlex: 1 },
  { name: "attempts", header: "attempts", defaultFlex: 1 }
];

const gridStyle = { minHeight: 500 };

const query_db = (
  { skip, limit, sortInfo },
  keywords,
  start,
  end,
  selectedWebsites,
  selectedStatus,
  setError
) => {
  //   alert(JSON.stringify(limit))

  let status_fields = "";
  selectedStatus.map((status) => {
    status_fields += status.value + ",";
  });

  if (status_fields[status_fields.length - 1] === ",")
  status_fields = status_fields.slice(0, status_fields.length - 1);

  let websites = "";
  selectedWebsites.map((website) => {
    websites += website.value + ",";
  });
  if (websites[websites.length - 1] === ",")
  websites = websites.slice(0, websites.length - 1);


  const base_url = "https://admin-api.infobring.io/";

  const search_str = keywords === "" ? "[all_results]" : keywords;
  let url =
    base_url +
    "links/" +
    search_str +
    "/skip/" +
    skip +
    "/limit/" +
    limit +
    "?min_date=" +
    handleDate(start) +
    "&max_date=" +
    handleDate(end) +
    "&website_name=" +
    websites +
    "&status_fields=" +
    status_fields;

  if (sortInfo !== null && sortInfo !== undefined)
    url += "?sort_field=" + sortInfo.name + "?sort_dir=" + sortInfo.dir;

  return fetch(url, { 
    // mode: 'cors',
    // credentials: 'include',
    method: "GET", 
    headers: authHeader() 
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error !== undefined) {
        setError(data.error);
        data = [];
        let totalCount = 0;
        return Promise.resolve({ data, count: parseInt(totalCount) });
      }
      if (
        data.results === undefined ||
        data.results[0].metadata === undefined ||
        data.results[0].metadata.length === 0
      ) {
        let data = [];
        let totalCount = 0;
        return Promise.resolve({ data, count: parseInt(totalCount) });
      }
      setError("");
      let totalCount = data.results[0].metadata[0].total;
      data = data.results[0].data.map((result) => ({
        id: result._id.$oid,
        scraped: handleDate(result.scraped.$date),
        published: handleDate(result.published.$date),
        url: result.url,
        homepage: result.homepage,
        attempts: result.attempts,
        status: result.status
      }));
      // alert(JSON.stringify(data))
      return Promise.resolve({ data, count: parseInt(totalCount) });
    });
};

const Links = () => {
  const [keywords, setKeywords] = useState("");
  const [error_message, setError] = useState("");
  const [state, setDate] = useState({
    start: moment().subtract(30, "days"),
    end: moment(),
  });
  const { start, end } = state;
  const handleCallback = (start, end) => {
    setDate({ start, end });
  };

  const [website_list, setWebsite_list] = useState("");

    const [selectedWebsites, setSelectedWebsites] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([{ value: "error" }]);
  const dataSource = useCallback(query_db, [
    start,
    end,
    selectedStatus,
    selectedWebsites
  ]);
  
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  const [selectedRows, setSelectedRows] = useState({});

  const onRowSelectionChange = useCallback(({ selected }) => {
    setSelectedRows(selected);
  }, []);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    const fetch_websites = async () => setWebsite_list(await get_websites());
    fetch_websites();

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const onCellClick = useCallback((event, cellProps) => {
    const { columnIndex, rowIndex, value } = cellProps;

    const redirectLink = (c, r) => {
      if (c === 5) {
        window.open(value, "_blank", "toolbar=0,location=0,menubar=0");
      }
    };
    redirectLink(columnIndex, rowIndex);
  }, []);

  let isMobile = width <= 768;

  let label = "";

  if (isMobile === false)
    label = start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY");
  else label = "";

  const onFilter = async () => {
    setOpen(false);
    // setActivePage(1);
    // await update_data(1);
  };



  const onFormSubmit = (event) => {
    event.preventDefault();
    // if (keywords === "") alert("Please enter some text.");
    // alert(handleDate(start) + "    " + handleDate(end))
  };

  const hkeyChange = (e) => {
    setKeywords(e.target.value);
  };

  const update_links = (mode) => {

    const url = "https://admin-api.infobring.io/update_articles/?mode=" + mode + "&links=true";
    const params = {
      method: "POST",
      headers: authHeader(),
      body: 
        JSON.stringify(toArray(selectedRows)),
    };

    fetch(url, params)
      .then((response) => response.json())
      .then((data) => {
        alert(JSON.stringify(data));
        setSelectedRows({});
      })
      .catch((err) => {
        alert(err);
      });
  };

  const mongoSearch = () => {
  
    return (
      <div>
        <div>
          <br />
          <h2>Check Links</h2>
          <div className="content">
            <form
              style={{ display: "inline-flex", justifyContent: "center" }}
              onSubmit={onFormSubmit}
            >
              <input
                class="form-control"
                style={{ minWidth: "40vw" }}
                value={keywords}
                onChange={(e) => hkeyChange(e)}
                type="text"
                placeholder="Enter URL"
              />
              &nbsp;&nbsp;
              {/* <button type="submit" className="btn btn-primary">
                    Search
                  </button> */}
              <button
                className="btn btn-primary"
                onClick={() => setOpen((o) => !o)}
              >
                <BsFilter />
                &nbsp;&nbsp;Filter&nbsp;&nbsp;
              </button>
              <Popup
                open={open}
                className="popup"
                closeOnDocumentClick={false}
                position="right center"
                onClose={onFilter}
              >
                <a className="close" onClick={closeModal}>
                  &times;
                </a>

                <div class="form-group row custom-group">
                  <form>
                    <label>Date Range</label>
                    <DateRangePicker
                      initialSettings={{
                        startDate: start.toDate(),
                        endDate: end.toDate(),
                        ranges: {
                          Today: [moment().toDate(), moment().toDate()],
                          Yesterday: [
                            moment().subtract(1, "days").toDate(),
                            moment().subtract(1, "days").toDate(),
                          ],
                          "Last 7 Days": [
                            moment().subtract(6, "days").toDate(),
                            moment().toDate(),
                          ],
                          "Last 30 Days": [
                            moment().subtract(29, "days").toDate(),
                            moment().toDate(),
                          ],
                          "This Month": [
                            moment().startOf("month").toDate(),
                            moment().endOf("month").toDate(),
                          ],
                          "Last Month": [
                            moment()
                              .subtract(1, "month")
                              .startOf("month")
                              .toDate(),
                            moment()
                              .subtract(1, "month")
                              .endOf("month")
                              .toDate(),
                          ],
                        },
                      }}
                      onCallback={handleCallback}
                    >
                      <div id="reportrange" className="form-control cal-input">
                        <BsCalendar />
                        &nbsp;&nbsp;<span>{label}</span>
                      </div>
                    </DateRangePicker>
                    <br />
                    <br />
                    <div class="form-group row">
                      <label>Status</label>
                      <ReactMultiSelectCheckboxes
                        id="status-fields"
                        width="150px"
                        hideSearch={true}
                        placeholderButtonLabel="Status Fields"
                        defaultValue={selectedStatus}
                        onChange={setSelectedStatus}
                        options={status_fields}
                      />
                    </div>
                    <div class="form-group row">
                    <label>Websites</label>
                      <ReactMultiSelectCheckboxes
                        className="multi-select"
                        id="websites"
                        defaultValue={selectedWebsites}
                        onChange={setSelectedWebsites}
                        options={website_list}
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary col-md-12 text-center"
                      onClick={closeModal}
                    >
                      Filter Results
                    </button>
                  </form>
                </div>
              </Popup>
            </form>
          </div>
        </div>
        <br />
        <hr />
        <div id="growth">
          <div class="error">{error_message}</div>
          <span>
            <button
              disabled={JSON.stringify(selectedRows) === "{}"}
              type="button"
              className="btn btn-primary text-center"
              onClick={() => update_links('rescrape')}
            >
              Rescrape
            </button>
          </span>
          <br />
          <br />

          <ReactDataGrid
            idProperty="id"
            style={gridStyle}
            checkboxColumn={true}
            selected={selectedRows}
            onSelectionChange={onRowSelectionChange}
            checkboxOnlyRowSelect={true}
            columns={columns}
            pagination={"remote"}
            dataSource={(d) =>
              dataSource(
                d,
                keywords,
                start,
                end,
                selectedWebsites,
                selectedStatus,
                setError
              )
            }
            defaultLimit={10}
            sortable={false}
            editable={true}
            onCellClick={onCellClick}
            // rtl={true}
          />
        </div>
        <br />
        <br />
      </div>
    );
  };
  if (!AuthService.getCurrentUser()) {
    history.push("/login");
    window.location.reload();
  }
  return (
    <div style={{ textAlign: "center" }}>
      <br />
      {mongoSearch()}
    </div>
  );
};

export default Links;
