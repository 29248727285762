import authHeader from "../services/auth-header";

const query_spider = async (id) => {
    const base_url = "https://admin-api.infobring.io/";
  let url = base_url + "spider/" + id;
  return await fetch(url, { 
    // mode: 'cors',
    // credentials: 'include',
    method: "GET", 
    headers: authHeader() 
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error !== undefined) {
        // setError(data.error);
        data = [];
        return data;
      }
      if (data.spider === undefined) {
        let data = [];
        return data;
      }
      else
      {
        return data.spider[0];
      }
    });
};

export default query_spider;