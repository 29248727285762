import React, { useState, useRef, useCallback } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { GrAdd } from "react-icons/gr";
import { MdDateRange, MdHourglassFull } from "react-icons/md";
import query_db from "./GetSpiders";
import { useHistory } from "react-router-dom";
import AuthService from "../services/auth.service";

const gridStyle = { minHeight: 400 };

const Spiders = () => {
  let history = useHistory();
  const data = query_db();
  const [rows, setRows] = useState(data);
  const [gridRef, setGridRef] = useState(null);
  const [searchText, setSearchText] = useState("");

  const searchTextRef = useRef(searchText);
  searchTextRef.current = searchText;

  const render = ({ value }) => {
    if (typeof value === "boolean") {
      value =
        value === true ? (
          <img
            alt=""
            width={20}
            height={20}
            src="https://upload.wikimedia.org/wikipedia/commons/3/31/Button_Icon_Green.svg"
          />
        ) : (
          <img
            alt=""
            width={20}
            height={20}
            src="https://upload.wikimedia.org/wikipedia/commons/2/27/Button_Icon_Black.svg"
          />
        );
      return value;
    } else if (typeof value === "object" && value.Start !== undefined) {
      const color = value.Errors > 0 ? "red" : "none";
        value = (
        <div>
          <p>
            <MdDateRange style={{ verticalAlign: "-10%" }} /> &nbsp;
            <b>{value.Start}</b> &nbsp;&nbsp;
            <MdHourglassFull style={{ verticalAlign: "-10%" }} /> <b>{Math.round(value.Elapsed)}</b> s
          </p>
          <p>
            Pages: <b>{value.Pages}</b>&nbsp;&nbsp; Items: <b>{value.Items}</b>&nbsp;&nbsp;
            <span style={{ color: color }}>Errors: <b>{value.Errors}</b> </span>
          </p>
        </div>
      );

      return value;
    }

    const lowerSearchText = searchTextRef.current.toLowerCase();
    if (!lowerSearchText) {
      return value;
    }
    const str = value + ""; // get string value
    const v = str.toLowerCase(); // our search is case insensitive
    const index = v.indexOf(lowerSearchText);

    if (index === -1) {
      return value;
    }

    return [
      <span key="before">{str.slice(0, index)}</span>,
      <span key="match" style={{ background: "yellow", fontWeight: "bold" }}>
        {str.slice(index, index + lowerSearchText.length)}
      </span>,
      <span key="after">{str.slice(index + lowerSearchText.length)}</span>,
    ];
  };

  const initialColumns = [
    {
      name: "id",
      header: "ID",
      sortable: true,
      defaultFlex: 1,
      visible: false,
      render,
    },
    {
      name: "name",
      header: "Name",
      defaultFlex: 2,
      render,
    },
    {
      name: "homepage",
      header: "Homepage",
      defaultFlex: 1,
      render,
    },
    {
      name: "description",
      header: "Description",
      defaultFlex: 1,
      render,
    },
    {
      name: "timezone",
      header: "Timezone",
      defaultFlex: 0.8,
      render,
    },
    {
      name: "base_url",
      header: "Base URL",
      defaultFlex: 1.5,
      render,
    },
    {
      name: "stats",
      header: "Last Run Stats",
      defaultFlex: 1.5,
      render,
    },
    {
      name: "active",
      header: "Active",
      defaultFlex: 1,
      textAlign: "center",
      render,
    },
  ];

  const onFormSubmit = (event) => {
    event.preventDefault();
  };

  const [columns] = useState(initialColumns);

  const onSearchChange = async ({ target: { value } }) => {
    setSearchText(value);
    const lowerSearchText = value && value.toLowerCase();
    const results = await Promise.resolve(data);
    // alert(JSON.stringify(results))
    const filtered_results = results.filter((res) =>
      res.name.toLowerCase().includes(lowerSearchText)
    );
    setRows(filtered_results);
  };

  const onCellClick = useCallback((event, cellProps) => {
    const { columnIndex, rowIndex, value } = cellProps;


    const redirectLink = async (c, r) => {
      if (c === 0) {
        const results = await Promise.resolve(data);
        let index = results.findIndex( element => {
          if (element.name === value) {
            return true;
          }
        });
        window.location = "/spider/" + results[index].id;
      }
    };
    redirectLink(columnIndex, rowIndex);
  }, []);

  
  if (!AuthService.getCurrentUser()) {
    history.push("/login");
    window.location.reload();
  }
  return (
    <div>
      <h3>
        Spiders{" "}
        <img
          alt=""
          width="2%"
          style={{ filter: "brightness(0) invert(1)" }}
          src={"http://getdrawings.com/free-icon-bw/spider-icon-1.png"}
        />{" "}
      </h3>
      <form
        style={{ display: "inline-flex", justifyContent: "center" }}
        onSubmit={onFormSubmit}
      >
        <input
          type="text"
          class="form-control"
          style={{ paddingTop: 5, color: "black", width: "30vw" }}
          value={searchText}
          placeholder="Enter Spider Name"
          onChange={onSearchChange}
        />
        <a href="/spider/new">
          <GrAdd style={{ margin: "5px", filter: "brightness(0) invert(1)" }} />
        </a>
      </form>
      <br />
      <br />

      <div id="growth">
        <ReactDataGrid
          handle={setGridRef}
          idProperty="id"
          style={gridStyle}
          columns={columns}
          dataSource={rows}
          editable={true}
          // onRowClick={onRowClick}
          rowHeight={null}
          onCellClick={onCellClick}
          rtl={false}
        />
      </div>
    </div>
  );
};

export default Spiders;
