const form_template = {
    _id: "new",
    name: "",
    homepage: "",
    description: "",
    timezone: "Asia/Dubai",
    archived: false,
    settings: {
      name: "",
      active: false,
      schedule: "0-23",
      base_url: "",
      base_url_suffix: "",
      base_url_prefix: "",
      url: "",
      rss: false,
      history: 3,
      titles_found_maxcount: 10,
      enable_js: false,
      enable_zenscrape: false,
      rotate_proxies: '',
      data_center: false,
      articles_list: { page: "list", xpath: "", css: "" },
      title: { page: "list", xpath: "", css: "" },
      news_type: { page: "list", xpath: "", css: "" },
      published: {
        page: "list",
        xpath:
          "",
        css: "",
      },
      article_url: {
        page: "list",
        xpath: "",
        css: "",
      },
      next_page: {
        page: "list",
        xpath: "",
        css: "",
      },
      image_url: [
        {
          page: "article",
          xpath:
            "",
          css: "",
          base_url: "",
          caption: { xpath: "", css: "" },
        },
      ],
      text: [
        {
          page: "article",
          xpath:
            "",
          css: "",
        }
      ],
      lua_script: "",
    },
    country: "",
    language: "",
  };

  export default form_template;