import React, { useState, useEffect } from "react";
import "../App.css";
import { GiSandsOfTime, GiClockwork, GiCheckMark } from "react-icons/gi";
import { RotateSpinner } from "react-spinners-kit";
import authHeader from "../services/auth-header";

const solidLineOrange = "3px solid orange";
const solidLinePurple = "3px solid purple";
const solidLineGreen = "3px solid greenyellow";

const TestResult = ({ closeModal, jobid, log_url, homepage, bot_type }) => {
  const [json_data, setData] = useState({ status: "", data: {} });

  async function get_job_status(jobid, timer_id, homepage, bot_type) {
    if (jobid === "") return;
    const base_url = "https://admin-api.infobring.io/";
    let url = base_url + "job/" + jobid + "/" + homepage + "/" + bot_type;

    await fetch(url, { 
      // mode: 'cors',
      // credentials: 'include',
      method: "GET", 
      headers: authHeader() 
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["status"] === undefined) data["status"] = "pending";
        setData(data);
        if (data.status === "finished") clearInterval(timer_id);
      })
      .catch((err) => setData("Error: " + err));
  }

  useEffect(() => {
    const id = setInterval(() => {
      get_job_status(jobid, id, homepage, bot_type);
    }, 1000);
    return () => {
      clearInterval(id);
    };
  }, [jobid]);

  const processBar = () => {
    return (
      <div className="process-bar popup-modal-content">
        <div
          className="process-line-before"
          style={{
            borderTop: solidLinePurple,
          }}
        ></div>
        <div
          className="process"
          style={{
            backgroundColor: ["pending"].includes(json_data.status)
              ? "purple"
              : "",
            border: "3px solid purple",
          }}
        >
          <GiSandsOfTime
            className="process-icon"
            style={{
              color: ["pending"].includes(json_data.status)
                ? "white"
                : "purple",
            }}
          />
          <div
            className="process-text"
            style={{
              color: ["pending"].includes(json_data.status) ? "purple" : "",
            }}
          >
            Pending
          </div>
        </div>
        <div
          className="process-line-after"
          style={{
            borderTop: ["running", "finished"].includes(json_data.status)
              ? solidLineOrange
              : "",
          }}
        ></div>
        <div
          className="process-line-before"
          style={{
            borderTop: ["running", "finished"].includes(json_data.status)
              ? solidLineOrange
              : "",
          }}
        ></div>
        <div
          className="process"
          style={{
            backgroundColor: ["running"].includes(json_data.status)
              ? "orange"
              : "",
            border: "3px solid orange",
          }}
        >
          <GiClockwork
            className="process-icon"
            style={{
              color: ["running"].includes(json_data.status)
                ? "white"
                : "orange",
            }}
          />
          <div
            className="process-text"
            style={{
              color: ["running"].includes(json_data.status) ? "orange" : "",
            }}
          >
            Running
          </div>
        </div>
        <div
          className="process-line-after"
          style={{
            borderTop: ["finished"].includes(json_data.status)
              ? solidLineGreen
              : "",
          }}
        ></div>
        <div
          className="process-line-before"
          style={{
            borderTop: ["finished"].includes(json_data.status)
              ? solidLineGreen
              : "",
          }}
        ></div>
        <div
          className="process"
          style={{
            backgroundColor: ["finished"].includes(json_data.status)
              ? "greenyellow"
              : "",
            border: "3px solid greenyellow",
          }}
        >
          <GiCheckMark
            className="process-icon"
            style={{
              color: ["finished"].includes(json_data.status)
                ? "white"
                : "greenyellow",
            }}
          />
          <div
            className="process-text"
            style={{
              color: ["finished"].includes(json_data.status)
                ? "greenyellow"
                : "",
            }}
          >
            Done
          </div>
        </div>
        <div className="process-line-end"></div>
        <div className="process-line-end"></div>
      </div>
    );
  };

  return (
    <div className="popup-modal">
      <a className="popup-modal-close" onClick={closeModal}>
        &times;
      </a>

      <div className="popup-modal-header"> Progress </div>
      <div className="content">
        <br />
        <RotateSpinner
          style={{ position: "relative" }}
          color={
            ["pending"].includes(json_data.status)
              ? "purple"
              : ["running"].includes(json_data.status)
              ? "orange"
              : "#282c34"
          }
          size={30}
        />
        {processBar()}
        <br />
        <span style={{ color: "white", paddingBottom: "5px" }}>
          Logfile:&nbsp;&nbsp;
          <a target="_blank" rel="noreferrer" href={log_url}>
            log
          </a>
        </span>
      </div>
      <div
        style={{
          display: json_data.status === "finished" ? "block" : "none",
        }}
      >
        <div className="popup-modal-header">
          <button className="btn btn-primary"
            onClick={() => {
                var winPrint = window.open('', '', 'left=200,top=50,width=800,height=600,toolbar=0,scrollbars=0,status=0');
                winPrint.document.write('<title>Results</title><br /><br /><div>' + JSON.stringify(json_data.data) + '</div>');
                winPrint.document.close();
            }}
          >
            Results
          </button>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default TestResult;
