export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user === undefined || user === null)
    return {}
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', 'Bearer ' + user.token);
  headers.append('Accept', 'application/json');
  headers.append('Access-Control-Allow-Origin', '*');
  
  if (user && user.token) {
    return headers
  } else {
    return {};
  }
}